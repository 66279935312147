<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0">Update Leave</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateOrCreate"
        >
          Save
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Cancel
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined flat>
          <v-card-text>
            <v-col md="10">
              <v-form
                ref="customerForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="updateOrCreate"
              >
                <v-container fluid>
                  <v-row>
                    <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Employee</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        v-model="customer.employee"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :rules="[vrules.required(customer.employee, 'First Name')]"
                        :class="{ required: !customer.employee }"
                        :items.sync="genderList"
                        id="gender"
                        placeholder="Employee"
                      >
                      </SelectInput>
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                      <label for="leave-type" class="btx-label mt-2">Leave Type</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <v-radio-group hide-details v-model="customer.paidUnpaid" row class="d-flex">
                        <v-radio label="PAID" value="paid"></v-radio>
                        <v-radio label="UNPAID" value="unpaid"></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-birth" class="btx-label mt-2">Leave Day</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <v-radio-group hide-details v-model="customer.leaveday" row>
                        <v-radio label="HALF DAY" value="half day"></v-radio>
                        <v-radio label="FULL DAY" value="full day"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-start" class="btx-label mt-2 required">Start Date</label>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <DatePicker
                        hide-details
                        v-model="customer.date_of_start"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-start"
                        placeholder="Start Date"
                      >
                      </DatePicker>
                    </v-col>

                    <v-col md="3" class="text-right py-0"
                      ><label for="title" class="btx-label mt-2 required">Exp. Date</label></v-col
                    >
                    <v-col md="3" class="py-0">
                      <DatePicker
                        hide-details
                        v-model="customer.date_of_end"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-start"
                        placeholder="Exp. Date"
                      >
                      </DatePicker>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-start" class="btx-label mt-2 required">Past Date</label>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <DatePicker
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-start"
                        placeholder="Past Date"
                      >
                      </DatePicker>
                    </v-col>

                    <v-col md="3" class="text-right py-0">
                      <label for="phone-number" class="btx-label mt-2 required"
                        >Duration</label
                      ></v-col
                    >
                    <v-col md="3" class="py-0">
                      <TextInput
                        hide-details
                        text="number"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="address-line-2"
                        v-model="customer.duration_time"
                        :rules="[vrules.required(customer.duration_time, 'First Name')]"
                        :class="{ required: !customer.duration_time }"
                        placeholder="Duration"
                      >
                      </TextInput>
                    </v-col>

                    <v-col md="3" class="py-0">
                      <label for="reason" class="btx-label mt-2">Reason</label>
                    </v-col>
                    <v-col md="9" class="">
                      <TextAreaInput
                        v-model="message"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Reason"
                        :counter="250"
                        :rows="7"
                        :cols="30"
                      >
                      </TextAreaInput>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "leave-create",
  title: "Create Customer",
  data() {
    return {
      pageLoading: false,
      formValid: true,
      customerId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "ALAN HENG",
          value: "ALAN HENG",
        },
        {
          text: "Krishan",
          value: "krishan",
        },
        {
          text: "Radhika",
          value: "radhika",
        },
      ],
      customer: {
        title: null,
        employee: null,
        last_name: null,
        gender: null,
        paidUnpaid: null,
        leaveday: null,
        date_of_start: null,
        date_of_end: null,
        phone_number: null,
        address_line_1: null,
        duration_time: null,
        unit_number: null,
        postal_code: null,
        country: null,
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    DatePicker,
    TextAreaInput,
  },
  methods: {
    pageTitle() {
      if (this.customerId) {
        return "Update Leave";
      }
      return "Update Leave";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_CUSTOMER(_this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        title: customer.title,
        employee: customer.employee,
        last_name: customer.last_name,
        paidUnpaid: customer.paidUnpaid,
        leaveday: customer.leaveday,
        gender: customer.gender,
        phone_number: customer.phone_number,
        date_of_start: customer.date_of_start,
        date_of_end: customer.date_of_end,
        address_line_1: customer.address_line_1,
        duration_time: customer.duration_time,
        unit_number: customer.unit_number,
        postal_code: customer.postal_code,
        country: customer.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "customer-update") {
      const { id } = params;
      if (id) {
        this.customerId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>
